import Footer from "../components/Footer";
import Header from "../components/Header";
import './Gallery.css'

import { useState, useEffect, useRef } from 'react';

//import { observer } from 'mobx-react';

const GalleryPage = () => {

  const [page, setPage] =  useState(0);
  const [apeImages, setApeImages] =  useState([]);

  const loadMoreApes = () => {
      if (apeImages.length >= 8888) {
          return;
      }
      //console.log("more apes")
      const newImages = [];
      for (var i = 0; i < 64; i++) {
          var ix = apeImages.length + i;
          var img = `/img/apes/${ix}.png`;
          newImages.push(<div className="ape" key={ix}><img  src={img} alt=''/></div>);
      }   
      setApeImages([...apeImages, ...newImages]); 
      //console.log(apeImages);
  }

  const loader = useRef(null);

  useEffect(() => {
        var options = {
          root: null,
          rootMargin: "20px",
          threshold: 1.0
        };
      // initialize IntersectionObserver
      // and attaching to Load More div
        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
          observer.observe(loader.current)
        }

  }, []);


  useEffect(() => {
      loadMoreApes();
  }, [page])

  // here we handle what happens when user scrolls to Load More div
   // in this case we just update page variable
   const handleObserver = (entities) => {
      const target = entities[0];
      if (target.isIntersecting) {   
          setPage((page) => page + 1)            
      }
    }


  return (
        <div>
            <Header />
            <div className="wrapper">

              <div className="grid-classic-body">
                <section>
                    <h2>Meet all the apes</h2>
                        <div className="gallery">
                            { apeImages } 
                        </div>
                        <div className="loading" ref={loader}></div>
                        <div className='grid-items'></div>
                </section>
              </div>
            </div>

            <Footer />
        </div>
  );
}

export default GalleryPage;
