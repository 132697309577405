import Header from "../components/Header";
import Footer from "../components/Footer";
import ContractRead from '../components/MyContractRead'

import { useAccount } from 'wagmi'


function MyApes() {
    const { address, isConnected } = useAccount()
   
    if (isConnected)
        return(
            <div>
                <Header />
                <div className="wrapper">
                    <div className="grid-classic-body">
                        { /* <p>Connected to {address}</p> */ }
                        <ContractRead userAddy={address} />
                    </div>
                </div>
                <Footer />
            </div>
        )
    return (
        <div>
            <Header />
                <div className="wrapper">
                    <div className="grid-classic-body">
                        <h2>My Apes</h2>
                        <p>You need to connect your wallet</p>
                    </div>
                </div>
            <Footer />
        </div>
    );
}

export default MyApes;