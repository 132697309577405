
import Header from '../components/Header';
import Footer from '../components/Footer';
import MintHome from '../components/MintHome';

import { useAccount } from 'wagmi'

import { useEffect, useState } from 'react';

function Home() {

    const {isConnected } = useAccount()

    /* Mouse position */
    const [localMousePos, setLocalMousePos] = useState({});

    // Appelé quand on bouge la souris dans le cadre
    const handleMouseMove = (event) => {
        // 👇 Get mouse position relative to element
        const localX = event.clientX - event.target.offsetLeft;
        const localY = event.clientY - event.target.offsetTop;
    
        setLocalMousePos({ x: localX, y: localY });

        // Update la position du laser
        draw();
    };
    /* end Mouse position */

   
    /* Draw lasers init */
    useEffect(() => {
        draw();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    // https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook

    const draw = () => {
        const canvas = document.getElementById("tutorial");
        //console.log(localMousePos)
        if (canvas.getContext) {
            const ctx = canvas.getContext("2d");
            const img = new Image();
            img.src = "/img/ape-only-hero.png";

            img.onload = function () {

                canvas.width = img.naturalWidth; //470
                canvas.height = img.naturalHeight; //309

                ctx.strokeStyle = "#fff600";
                ctx.lineWidth = 2;

                ctx.drawImage(img, 0, 0);
                ctx.beginPath();
                ctx.lineTo(242, 92); // Left Eye Start
                ctx.lineTo(localMousePos.x-5, localMousePos.y);
                ctx.stroke();

                ctx.beginPath();
                ctx.lineTo(274, 92); // Right Eye Start
                ctx.lineTo(localMousePos.x+5, localMousePos.y);
                ctx.stroke();
            }
      }
    }
  

return(
        <div>
            <Header />
                
            <div className="wrapper">

                <div className="grid-hero">
                    <div className="txt-hero">

                        <p>An NFT collection of 8888 unique Apes living on the Ethereum blockchain, for a bit longer than other famous Apes :)</p>
                        <p>You're not too late to join the family, get your Ape now!</p>
                        
                        {isConnected &&<MintHome />}
                        
                        <p>You can mint up to 50 apes at a time. Flat price, 0.1 ETH.</p>
                        {!isConnected && <div className='txtConnectWallet'>Connect your wallet in order to mint.</div>}
                    </div>
                    <div className="img-hero">
                        <canvas id="tutorial" onMouseMove={handleMouseMove}></canvas>
                        {/* <div>({localMousePos.x}, {localMousePos.y})</div> */}
                    </div>
                </div>

                

                <div className="banana-section">
                    <div className="x">
                        <img className="y" src="/img/banana-120.png" alt="moving banana"/>
                    </div>
                </div>

                

                <div className="paragraphs">
                    <div>
                        <h2>Distribution</h2>
                        <p>The launch of the project was conducted fairly, with a random distribution of NFTs.</p>
                        <p>The price is fixed and set in the smart contract, and it cannot be adjusted.</p>
                        <p>At the launch and only for a week during a week, some apes were available to be claimed as an airdrop for holders of other NFTs.
                        </p>

                        <h2>Team</h2>
                        <p>Dev's sort of taking a power nap, but still can be found.</p>
                        <p>Albert II is now the captain of the ship, and you can catch me kickin' it on Discord and Twitter.</p>
                    </div>
                    <div className="theimage">
                        <img className="bottom" src="/img/sleeping-ape.png" alt="sleeping ape" />
                    </div>
                </div>
                <div className="paragraphs paragraph_nogrid">
                    <div>
                        <h2>Join The Apeventure</h2>

                        <p>The Apes With Lasers have a blast hanging out in their jungle hideout.</p>

                        <p>Growing their numbers, the Apes gathered to explore the jungle and they stumbled upon a mysterious maze deep in the woods. <br />
                        It's said this maze leads to the legendary lost temple, where some lucky apes got gifted with laser powers!</p>

                        <p>A group of intrepid ape explorers is on a quest to find it, and they're hoping you'll join the party and help grow their gang.</p>

                        <p>But buckle up, because there's a wild journey ahead, from alien strip clubs to daring escapes from space prisons, all while filling up the Apes' treasure chest.

Or, if you're not in the mood for adventure, you can always kick back and chill at the island's favorite pizza joint. 🐒🌴🍕</p>

                          </div>
                </div>

                <div className="free-ape">
                    <img src="/img/free-the-apes.png" alt="free the apes" />
                </div>

                <Footer />

            </div>
        
        </div>
);
}
export default Home;