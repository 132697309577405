import { useContractRead } from 'wagmi'
import { useNetwork } from 'wagmi'
import GetTokenId from './GetTokenId';

import {contractAddress, contractAddressSepolia, contractAbi} from './ScUtils'

function MyContractRead(userAddy) {

  // En dur pour debug 
  // const USER_ADDRESS = '0x370B05b98494ce96F3E6FddE317401DcD245b230'; // 8
  // const USER_ADDRESS = '0xe5119CbdF709fD8831b29F08411DF97e191af8c3'; // 25
  // const USER_ADDRESS = '0x53c52dA1f8Dc49cd9e4E03C9F62209267241AcEA'; // 10
  // const USER_ADDRESS = '0x1b7811de25B79233a117937e105673F203295210'; // 4
  // const USER_ADDRESS = '0x69969e78e83d5d287540a3ad4bf86992070a5bef'; //2

    const USER_ADDRESS = userAddy.userAddy

    // Check which chain we're using
    const { chain } = useNetwork()
    let myContractAddress =  contractAddress

    if (chain.name === "Sepolia"){
        myContractAddress = contractAddressSepolia
    }


    const { data:balanceOfData, isLoading } = useContractRead({
      address: myContractAddress,
      abi: contractAbi,
      functionName: 'balanceOf',
      args: [USER_ADDRESS]
    })

    // console.log(hexToInt(balanceOfData))

    if (isLoading) {
      return <>Loading User Info</>
    }

    return(
        <div>
            <GetTokenId userAddress={USER_ADDRESS} userBalance={balanceOfData} />
        </div>
    );
  }

export default MyContractRead;