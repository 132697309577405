function GalleryDisplay({prop}) {
    // /img/apes/${imageId}.png

    return(
        <div className="photo">
            <img src={"/img/apes/"+prop.imageId+".png"} />
            Ape #{prop.tokenId}
        </div>
    );
}

export default GalleryDisplay;