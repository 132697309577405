import { useContractRead } from 'wagmi'
import { useNetwork } from 'wagmi'

import { contractAddress, contractAddressSepolia, contractAbi, maxSupply, hexToInt} from './ScUtils'

function calcLeftToMint(totalSupply){
  return maxSupply - totalSupply;
}

function LeftToMint() {

    const { chain } = useNetwork()
    // Check which chain we're using
    let myContractAddress =  contractAddress

    if (chain.name === "Sepolia"){
        myContractAddress = contractAddressSepolia
    }

    const { data:totalSupplyData, isLoading } = useContractRead({
      address: myContractAddress,
      abi: contractAbi,
      functionName: 'totalSupply',
    })


    if (isLoading) {
      return <>Loading</>
    }

    return(
        <div>
          <p>There are <b>{calcLeftToMint(totalSupplyData)}</b> Apes left to mint.</p>
        </div>
    );
  }

export default LeftToMint;