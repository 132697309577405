import { Link } from 'react-router-dom';
import { useState } from 'react';
import { ConnectKitProvider, ConnectKitButton } from "connectkit";

import logoWebsite from '../images/logo-test.png';

function Header(){
    const [openMenu, setOpenMenu] = useState(false);
    const toggleMenu = () => {
        setOpenMenu(!openMenu);
    };

    
    return (
        <nav>
            <div className="logo">
                <Link to="/"><img src={logoWebsite} alt="AWL Logo" width="160" /></Link>
                
            </div>
                <ul className={openMenu ? "nav-links nav-active" : "nav-links"}>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/gallery">Gallery</Link></li>
                    <li><Link to="/myapes">My Apes</Link></li>
                    <li>
                  
                        <ConnectKitProvider>
                            <ConnectKitButton />
                        </ConnectKitProvider>
                
                    </li>
                </ul>
                <div className={openMenu ? "burger toggle-burger" : "burger"} onClick={toggleMenu}>
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                </div>
        </nav>
    )
}
export default Header;