import { useAccount, useNetwork } from 'wagmi'
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
} from 'wagmi'
import { ethers } from 'ethers'

import LeftToMint from './LeftToMint'
import { contractAddress, contractAddressSepolia, contractAbi} from './ScUtils'

import { useState } from 'react';


function MintHome() {

  const { address, isConnected } = useAccount()

  /* --- MINT --- */
  const { chain } = useNetwork()
  
  // Check which chain we're using
  let myContractAddress =  contractAddress

  if (chain.name === "Sepolia"){
      myContractAddress = contractAddressSepolia
  }

  const cartMax = 50
  //currentPrice = Web3.utils.toWei('0.1', 'ether');
  //const currentPrice = 100000000000000000 // 0.1 ETH
  const priceETH = 0.1
  
  let cartPriceInEth = 0

  let [cartQuantity, setMessage] = useState(1); // Init
  
  // ARGS : payableAmount, numberOfNfts
  let numberOfNfts = parseInt(cartQuantity)
  cartPriceInEth = priceETH*cartQuantity
  // Besoin toFixed pour ne pas afficher chiffres à virgules qui apparraissent. Revoie un String
  cartPriceInEth =  cartPriceInEth.toFixed(1)

  const { config } = usePrepareContractWrite({
    address: myContractAddress,
    abi: contractAbi,
    functionName: 'mintNFT',
    args: [numberOfNfts],
    overrides: {
      value: ethers.utils.parseEther(cartPriceInEth),
    },
  })
  const { data, write: mintNft} = useContractWrite(config)

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  })


  /* CART */
  
  const handleChange = (event) => {
      // 👇 Get input value from "event"
      let qty = parseInt(event.target.value);
      
      if (isNaN(qty)){
        qty=1;
      }
      //console.log(qty)
      
      if (qty > cartMax) {
          cartQuantity = cartMax;
      } else if (qty < 1) {
          cartQuantity = 1;
      } else {
          cartQuantity = qty;
      }

      setMessage(cartQuantity);
  };

  
  const increaseCart = () => {
    cartQuantity = parseInt(cartQuantity)
    cartQuantity += 1
    if (cartQuantity > cartMax) {
        cartQuantity = cartMax;
    }
    setMessage(cartQuantity);
  }

  const decreaseCart= () => {
    cartQuantity = parseInt(cartQuantity)
    cartQuantity -= 1;
    if (cartQuantity < 1) {
        cartQuantity = 1;
    }
    setMessage(cartQuantity);
  }

  function increaseMaxCart() {
    cartQuantity = parseInt(cartQuantity)
    cartQuantity = cartMax;
    setMessage(cartQuantity);
  }
  /* *** Fin CART *** */

  if (isConnected)
    return (
      <div>
       
        <p>Connected to {address}</p>
        <LeftToMint /> 

        {/* MINT PROCESS */}

        <div className="mint-form">
            <div className="inputNumber">
              <button onClick={ () => decreaseCart() }>-</button>
              <input
                type="number"
                min="1" max={cartMax} value={cartQuantity}
                id="message"
                name="message"
                onChange={handleChange}
              />
              <button onClick={ () => increaseCart() } >+</button>
              <button onClick={ () => increaseMaxCart() }>max</button>
          </div>
          <div className="mint-btn">
                <button
                  onClick={() => mintNft?.()}
                  disabled={!mintNft || isLoading}
                >{isLoading ? 'Minting...' : 'Mint for '+cartPriceInEth+' ETH'}
                </button>
                
          </div>
      </div>
      {isSuccess && (
          <div className="successMint">
            Successfully minted! <a href={`https://etherscan.io/tx/${data?.hash}`}>Etherscan</a>
          </div>
        )}
    </div>
    )
  return (
    <div>
        <p>Connect your Wallet</p>
    </div>

  );
}

export default MintHome;