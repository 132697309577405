import { useContractReads, useNetwork } from 'wagmi'
import {contractAddress, contractAddressSepolia, contractAbi, startingIndex, maxSupply,hexToInt} from './ScUtils'


import GalleryDisplay from './GalleryDisplay';
import './GalleryDisplay.css'


function imageTokenInfo(tokenId) {

  /*
  // Avant lancement de la collection quand startingIndex n'etait pas connu
  if (this.startingIndex === 0) {
      return "/images/ape-incognito.png";
  }*/

  let imageId = (tokenId + startingIndex) % maxSupply;

  // Objet avec tokenId et imageId

  const tokenInfo = {
      tokenId: tokenId,
      imageId: imageId
  }
  return (tokenInfo)
}

// userBalance c'est un prop, donc faut "déconstruire" avec {}
function GetTokenId({ userAddress, userBalance }) {

  const tokenIndices = [...Array(parseInt(userBalance)).keys()]; // crée un array [0, 1, ..., userBalance - 1]

    //console.log("Userbalance", userBalance, "Indices", tokenIndices)

    // Check which chain we're using
    const { chain } = useNetwork()
    let myContractAddress =  contractAddress

    if (chain.name === "Sepolia"){
        myContractAddress = contractAddressSepolia
    }

    // Read multiple pour récupérer tous les tokens à tous les index 
    // utilise useContractReads (avec un S), cf doc Wagmi
    const { data, isLoading } = useContractReads({
      contracts: tokenIndices.map(i => {
        return {
          address: myContractAddress,
          abi: contractAbi,
          functionName: 'tokenOfOwnerByIndex',
          args: [userAddress, i],
        }
      })
    });

    // console.log(data, isLoading, isError)
    
    
    if (!isLoading){
      const tokenInfoData  = data.map(i => imageTokenInfo(hexToInt(i)))

      return(
        <div>
           
            { /* <pre>{JSON.stringify(data.map(hexToInt))}</pre> */ }

            <p>You own {parseInt(userBalance)} Apes With Lasers.</p>
            <h2>My Apes</h2>
            <div className='grid-items'>
            {
              tokenInfoData.map(x => <GalleryDisplay key={x.tokenId}  prop={x} />)
            }
          
            </div>
    
        </div>
      );
      
    }
    else{
      return <div>Loading Apes...</div>
    }
    
    //console.log(JSON.stringify(data.map(hexToInt)))

  }

export default GetTokenId;