import React from 'react';
import ReactDOM from 'react-dom/client';
import { WagmiConfig } from 'wagmi';

import { mainnet, sepolia } from "wagmi/chains";
import { createClient } from 'wagmi'
import { getDefaultClient } from "connectkit";

import './index.css';
import App from './App';


const alchemyId = process.env.ALCHEMY_ID;

// Choose which chains you'd like to show
const chains = [mainnet,sepolia];

const client = createClient(
  getDefaultClient({
    appName: "Your App Name",
    alchemyId,
    chains,
  }),
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <WagmiConfig client={client}>
                <App />
        </WagmiConfig>
);