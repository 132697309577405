import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import GalleryPage from "./pages/Gallery";
import MyApes from "./pages/MyApes";


function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/gallery">
          <GalleryPage />
        </Route>
        <Route path="/myapes">
          <MyApes />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
