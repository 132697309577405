import logoWebsite from '../images/logo-website.png';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
//import { faMedium } from '@fortawesome/free-brands-svg-icons'

const twitterIcon = <FontAwesomeIcon icon={faTwitter} size={ "2xl" } />
const discordIcon = <FontAwesomeIcon icon={faDiscord} size={ "2xl" } />
//const mediumIcon = <FontAwesomeIcon icon={faMedium} />

function Footer(){
    return(
    <div className="footer">
        <div className="bgfooter">
                <div className="grid_footer">
                    <div>
                        <div className="socials-footer">
                        <a href="https://twitter.com/lasers_apes" target='_blank' className='twitterIco' rel="noopener noreferrer">{twitterIcon}</a>
                        <a href="https://discord.gg/aTTwQv8Egv" target='_blank' rel="noopener noreferrer">{discordIcon}</a>
                        </div>
                    </div>

                    <div></div>
                </div>
        </div>
    </div>
    )
}
export default Footer;